require('./bootstrap');
require('alpinejs');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');
require('@fortawesome/fontawesome-free/js/all.js');

$(document).ajaxError(function(evt, xhr, request, errorString) { 
    if (xhr.status === 419) {
        $.get(document.location.href, function(a, b, c, d, e) {
            /*console.log(a);
            console.log(b);
            console.log(c);
            console.log(d);
            console.log(e);
            console.log(c.getAllResponseHeaders());*/
        });
    } 
    else if (xhr.status === 401) {
        document.location.reload();
    } 
    else if (xhr.status === 500) {
        /*console.log(evt);
        console.log(xhr);
        console.log(request);
        console.log(errorString);*/
    }
});

$(document).on('click', '.ajax_load, .ajax-load', function(evt) {
	if ($(this).is('a') || $(this).is('input[type="submit"]') || $(this).is('button[type="submit"]')) {
        evt.preventDefault();
    }
    var that = this;
    if  ($(that).hasClass('disabled')) {
        return false;
    }
    if ($(that).data('beforeload')) {
        eval($(that).data('beforeload'));
    }
    $.get($(that).attr('href'), function(data) { 
        if(data.callback){
            eval(data.callback);
            if($(that).data('afterload')) {
                eval($(that).data('afterload'));
            }
            return;
        }
        if($(that).data('afterload')) {
            eval($(that).data('afterload'));
        }
        $('body').append(data);
    });
    if ($(this).is('a') || $(this).is('input[type="submit"]') || $(this).is('button[type="submit"]')) {
        return false;
    }
});

$(document).on('click', '.ajax_post, .ajax-post', function(evt) {
    evt.preventDefault();
    if  ($(this).hasClass('disabled')) {
        return false;
    }
    if ($(this).data('beforeload')) {
        eval($(this).data('beforeload'));
    }
    var post = JSON.parse(($(this).data('post') ?? '{}').replace(/'/g, '"'));
    $.each(post, function(k, v) {
        try {
            post[k] = window[v]();
        }
        catch(e) {
        }
    });
    post._token = $('meta[name="csrf-token"]').attr('content');
    $.post($(this).attr('href'), post, function(data) { if(data.callback){eval(data.callback);return;}$('body').append(data);});
    return false;
});

$(document).on('submit', '.ajax-form', function(evt) {
    evt.preventDefault();
    var that = this;
    if ($(that).data('beforepost')) {
        eval($(that).data('beforepost'));
    }
    var post = new FormData(that);
    $(that).find('input, select, textarea, button').each(function() {
        if(!$(this).is(':disabled')) {
            $(this).addClass('auto-enable');
        }
        $(this).prop('disabled', true).removeClass('is-invalid').parent().find('.invalid-feedback').remove();
    });
    $.ajax({
        url: $(that).prop('action'),
        type: $(that).data('method') ? $(that).data('method') : $(that).prop('method'),
        data: post, 
        processData: false,
        contentType: false,
        complete: function(xhr, status) {
            $(that).trigger('submit-complete');
            $(that).find('input.auto-enable, select.auto-enable, textarea.auto-enable, button.auto-enable').prop('disabled', false).removeClass('auto-enable');
        },
        success: function(data, status, xhr) {
            if (/*data.success && */data.callback) {
                eval(data.callback);
            }
        },
        error: function(xhr, status, error) {
            if (xhr.status === 403) {
                document.location.reload();
            }
            else if (xhr.status === 422) {
                /**
                 * Problème de validateur
                 */
                if (xhr.responseJSON !== undefined) {
                    var json = xhr.responseJSON;
                    for(var i in json.errors) {
                        var target = $('#'+i.replace(/\./g, '-'));
                        if(target.data("message") !== undefined){
                            target = $(target.data("message"));
                        }
                        target.addClass('is-invalid').parent().append('<div class="invalid-feedback">'+json.errors[i].join('<br>')+'</div>');
                    }
                }
            }
        }
    });
    return false;
});